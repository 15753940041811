// components/HeroCarousel.tsx
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Box, jsx } from "theme-ui";
import CarouselTitle from "./CarouselTitle";
import { Flex, Button } from "../../core";
import { motion, AnimatePresence, useTransform, useViewportScroll } from "framer-motion";
import ProgressCircle from './ProgressCircle';
//import { StaticImage, getImage } from "gatsby-plugin-image";
import useCarousel from './useCarousel';
import Arrow from './Arrow';
import { useResponsiveValue } from "../../../hooks/breakpoints";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { navigate } from "../../../helpers/navigate";

const MotionBox = motion.custom(Box);

const SLIDEDURATION = 6000; // Durée de chaque diapositive en secondes

export default function HeroCarousel({ slides, often = 0.3, backgroundColor }) {
  const hasMultipleSlides = slides.length > 1;
  const { currentIndex, isTransitioning, nextSlide, prevSlide, goToSlide } = useCarousel(slides, SLIDEDURATION, hasMultipleSlides);
  const currentSlide = slides[currentIndex];

  const ref = useRef(null);
  const [elementTop, setElementTop] = useState(0);
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, often], {
    clamp: false,
  });

  useLayoutEffect(() => {
    if (ref.current) {
      setElementTop(ref.current.offsetTop);
    }
  }, [ref]);

  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const imagesBg = useResponsiveValue([
    getImage(currentSlide.image.mobile),
    getImage(currentSlide.image.tablette),
    getImage(currentSlide.image.desktop),
  ]);

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        height: "100%", 
        width: "100%",
        overflow: "hidden",
      }}
    >

      { hasMultipleSlides && (
        <>  
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100px",
              zIndex: 4,
              pointerEvents: "none",
              background: "linear-gradient(to right, rgba(0,0,0,0.3), transparent 80%)",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: ["55%", "47%", "50%" ],
              left: 0,
              transform: "translateY(-50%)",
              zIndex: 4,
            }}
          >
            <Arrow onClick={prevSlide} />
          </Box>
        </>
      ) }

    
      {/* Carousel + Parallax */}
      <AnimatePresence>
        <MotionBox
          key={currentSlide.title}
          style={{ y }} // <- Parallax effect !
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <GatsbyImage
            image={imagesBg}
            alt={`Image Carousel ${currentSlide.title}`}
            style={{
              width: "100%",
              height: "100%",
              userDrag: "none",
              userSelect: "none",
            }}
            backgroundColor={backgroundColor}
            loading="eager"
          />
        </MotionBox>
      </AnimatePresence>

    { hasMultipleSlides && (
      <>
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100px",
            zIndex: 4,
            pointerEvents: "none",
            right: 0,
            left: "auto",
            background: "linear-gradient(to left, rgba(0,0,0,0.3), transparent 80%)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: ["55%", "47%", "50%" ],
            right: 0,
            transform: "translateY(-50%) rotate(180deg)",
            zIndex: 4,
          }}
        >
          <Arrow onClick={nextSlide} />
        </Box>
      </>
    ) }
   
    

      {/* Optionnel : Overlay dégradé */}
      {/* <Box
        sx={{
          position: "absolute",
          inset: 0,
          background: "rgba(0,0,0,0.3)",
          zIndex: 1,
        }}
      /> */}

      <Flex 
        direction="column"
        gap={[6, 0, null]}
        sx={{        
          position: "absolute",
          top: ["55%", "50%", null ],
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 3,
          width: "100%"
        }}
      >
        <CarouselTitle
          text={currentSlide.title}
          index={currentIndex}
          isLeaving={isTransitioning}
        />
          <AnimatePresence exitBeforeEnter>
            {currentSlide.btn && currentSlide.btn.text && !isTransitioning && (
              <MotionBox
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{
                  duration: 0.6,
                  ease: "easeInOut",
                }}
              >
                <Button
                    sx={{
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      navigate(currentSlide.btn.link);
                    }}
                  >
                    {currentSlide.btn.text}
                </Button>
              </MotionBox>
            )}
          </AnimatePresence>
      </Flex>

      {
        hasMultipleSlides && (
          <ProgressCircle
          duration={SLIDEDURATION}
          currentStep={currentIndex}
          totalSteps={slides.length}
          onSelect={(i) => {
            goToSlide(i);
          }}
        />
        )
      }
    </Box>
  );
}
