import { useState, useEffect, useCallback, useRef } from 'react';

/**
 * Hook personnalisé pour gérer un carousel avec transitions.
 *
 * @param {Array} slides - Liste des diapositives.
 * @param {number} [duration=6000] - Durée en millisecondes entre chaque diapositive en mode auto-play.
 * @param {boolean} [autoPlay=true] - Indique si l'auto-play est activé.
 * @returns {Object} - Objet contenant l'index actuel, l'état de transition et les fonctions de contrôle du carousel.
 */
function useCarousel(slides, duration = 6000, autoPlay = true) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const timeoutRef = useRef(null);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const goToSlide = useCallback(
    (index) => {
      setIsTransitioning(true);
      resetTimeout();
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          index < 0 ? slides.length - 1 : index >= slides.length ? 0 : index
        );
        setIsTransitioning(false);
      }, 500); // Durée de la transition en millisecondes
    },
    [slides.length, resetTimeout]
  );

  const nextSlide = useCallback(() => {
    goToSlide(currentIndex + 1);
  }, [currentIndex, goToSlide]);

  const prevSlide = useCallback(() => {
    goToSlide(currentIndex - 1);
  }, [currentIndex, goToSlide]);

  useEffect(() => {
    if (autoPlay) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => {
        nextSlide();
      }, duration);
    }

    return () => {
      resetTimeout();
    };
  }, [currentIndex, duration, nextSlide, resetTimeout, autoPlay]);

  return {
    currentIndex,
    isTransitioning,
    nextSlide,
    prevSlide,
    goToSlide,
  };
}

export default useCarousel;
