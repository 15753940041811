import React, { useMemo } from "react";
import { motion } from "framer-motion";

const SegmentedCircleProgress = ({
  currentStep,
  totalSteps,
  radius = 24,
  strokeWidth = 4,
  duration = 6000,
  onSelect,
}) => {
  const center = radius + strokeWidth;
  const size = (radius + strokeWidth) * 2;
  const gap = 20;

  const totalGap = gap * totalSteps;
  const adjustedAngle = (360 - totalGap) / totalSteps;

  // 🎨 Couleurs
  const filledColors = [
    "#2AA9E0", // bleu
    "#7DDE92", // vert
    "#FFD966", // jaune
    "#F7A072", // orange
    "#A972F7", // violet
  ];
  
  const emptyColors = [
    "#2AA9E099",
    "#7DDE9299",
    "#FFD96699",
    "#F7A07299",
    "#A972F799",
  ];
  
  const hoverColors = [
    "#5DA9C8",
    "#6BCB81",
    "#E6C85C",
    "#DE8F65",
    "#9B5CD7",
  ];
  

  const segments = useMemo(() => {
    return [...Array(totalSteps)].map((_, i) => {
      const startAngle = 180 + i * (adjustedAngle + gap) + gap / 2;
      const endAngle = startAngle + adjustedAngle;
      const largeArc = adjustedAngle > 180 ? 1 : 0;

      const start = polarToCartesian(center, center, radius, startAngle);
      const end = polarToCartesian(center, center, radius, endAngle);

      const d = `M ${start.x} ${start.y}
                 A ${radius} ${radius} 0 ${largeArc} 1 ${end.x} ${end.y}`;

      const arcLength = (2 * Math.PI * radius * adjustedAngle) / 360;

      return {
        d,
        arcLength,
        filledColor: filledColors[i % filledColors.length],
        emptyColor: emptyColors[i % emptyColors.length],
        hoverColor: hoverColors[i % hoverColors.length],
      };
    });
  }, [totalSteps, adjustedAngle, radius, gap]);

  return (
    <svg
      key={currentStep}
      width={size}
      height={size}
      style={{
        position: "absolute",
        bottom: 20,
        right: 20,
        zIndex: 5,
        filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 0.6))",
      }}
    >
      {/* Fond sombre circulaire */}
      <circle
        cx={center}
        cy={center}
        r={radius + strokeWidth / 2}
        fill="rgba(0, 0, 0, 0.06)"
      />

      {segments.map(({ d, arcLength, filledColor, emptyColor, hoverColor }, i) => {
        const isCurrent = i === currentStep;

        return (
          <motion.g
            key={i}
            whileHover={{ scale: isCurrent ? 1 : 1.18 }}
            style={{ cursor: isCurrent ? "auto" : "pointer" }}
            onClick={() => {
              if (!isCurrent) onSelect?.(i);
            }}
          >
            {/* Zone élargie pour hover */}
            <path
              d={d}
              fill="none"
              stroke="transparent"
              strokeWidth={strokeWidth + 15}
              strokeLinecap="round"
            />
            {/* Segment vide */}
            <path
              d={d}
              fill="none"
              stroke={emptyColor}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
            />
            {/* Segment actif */}
            {isCurrent && (
              <motion.path
                d={d}
                fill="none"
                stroke={filledColor}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeDasharray={arcLength}
                strokeDashoffset={arcLength}
                initial={{ strokeDashoffset: arcLength }}
                animate={{ strokeDashoffset: 0 }}
                transition={{ duration: duration / 1000, ease: "linear" }}
                whileHover={{ stroke: hoverColor }}
              />
            )}
          </motion.g>
        );
      })}
    </svg>
  );
};

function polarToCartesian(cx, cy, r, angleDeg) {
  const angleRad = (angleDeg * Math.PI) / 180.0;
  return {
    x: cx + r * Math.cos(angleRad),
    y: cy + r * Math.sin(angleRad),
  };
}

export default SegmentedCircleProgress;
