import React, { useEffect, useRef, useState } from "react";
import { graphql, navigate } from "gatsby";
import { Grid, Box, jsx } from "theme-ui";
import {
  Text,
  Heading,
  MotionFlex,
  Container,
  Button,
} from "../components/core";
import {
  Event,
  EventDate,
  EventPlace,
  EventTitle,
} from "../components/home/Event";
import HeroCarousel from "../components/home/HeroCarousel/HeroCarousel";
import { Vague, BackgroundVague } from "../components/svg";
import { InView } from "react-intersection-observer";
import Contact from "../components/Contact";
import { useResponsiveValue } from "../hooks/breakpoints";
import { getImage, StaticImage } from "gatsby-plugin-image";
import disableScroll from "disable-scroll";
import { firstLoad } from "../helpers/load";
import { Highlights } from "../components/Highlights";
import map from "lodash/map";

/** @jsx jsx */

export const IndexPageTemplate = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data;
  const frontmatter = markdownRemark.frontmatter;
  const { edges: events } = allMarkdownRemark;
  const refFirstLoad = useRef(firstLoad().state);
  const [isIntroComplete, setIsIntroComplete] = useState(!refFirstLoad.current);


  useEffect(() => {
    if (typeof window !== "undefined" && refFirstLoad.current) {
      if (isIntroComplete) {
        disableScroll.off();
      } else {
        disableScroll.on();
      }
    }
  }, [isIntroComplete, refFirstLoad]);

  const value = useResponsiveValue([12, null, 4]);
  const variants = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: (i % (12 / value)) * 0.4,
      },
    }),
    hidden: { opacity: 0 },
  };

  return (
    <Box sx={{ minHeight: "100%" }}>
      <Box sx={{ height: ["75vh", "calc(100vh - 104px)", null] }}>
        <HeroCarousel slides={frontmatter.carousel} />
      </Box>
      <Box
        sx={{
          zIndex: 5,
          backgroundColor: "background",
          position: "relative",
        }}
      >
        <Highlights
          sx={{
            transform: ["translate(0, 0)", "translate(-50%, -50%)", null],
            position: ["relative", "absolute", null],
            left: ["initial", "50%", null],
          }}
        >
          {map(frontmatter.highlights_home, ({ number, label }, index) => (
            <Highlights.Item key={index}>
              <Highlights.Item.Number>{number}</Highlights.Item.Number>
              <Highlights.Item.Label>{label}</Highlights.Item.Label>
            </Highlights.Item>
          ))}
        </Highlights>
        <Box
          as="section"
          sx={{
            position: "relative",
            paddingTop: [8, 24, null],
            paddingBottom: [0, 5, null],
          }}
        >
          <Container>
            <ContentTitle>Nos missions</ContentTitle>
            <Grid gap={[0, 5, 9]} columns={[1, 3, 3]}>
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref, entry }) => (
                  <MotionFlex
                    ref={ref}
                    direction="column"
                    positions={["center", "flex-start"]}
                    custom={0}
                    animate={inView ? "visible" : "hidden"}
                    variants={variants}
                    initial="hidden"
                    sx={{ marginBottom: [7, 0, 0] }}
                  >
                    <StaticImage
                      src="./home_nettoyer.png"
                      alt="Image nettoyer"
                      sx={{
                        width: "161px",
                      }}
                    />
                    <Heading
                      as="h3"
                      sx={{ color: "primary", marginTop: 3, marginBottom: 1 }}
                    >
                      Nettoyer
                    </Heading>
                    <Text sx={{ textAlign: "center" }} preLine>
                      {frontmatter.accueilMissions.nettoyer}
                    </Text>
                  </MotionFlex>
                )}
              </InView>
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref, entry }) => (
                  <MotionFlex
                    ref={ref}
                    direction="column"
                    positions={["center", "flex-start"]}
                    custom={1}
                    animate={inView ? "visible" : "hidden"}
                    variants={variants}
                    initial="hidden"
                    sx={{ marginBottom: [7, 0, 0] }}
                  >
                    <StaticImage
                      src="./home_sensibiliser.png"
                      alt="Image sensibiliser"
                      sx={{
                        width: "161px",
                      }}
                    />
                    <Heading
                      as="h3"
                      sx={{ color: "primary", marginTop: 3, marginBottom: 1 }}
                    >
                      Sensibiliser
                    </Heading>
                    <Text sx={{ textAlign: "center" }} preLine>
                      {frontmatter.accueilMissions.sensibiliser}
                    </Text>
                  </MotionFlex>
                )}
              </InView>
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref, entry }) => (
                  <MotionFlex
                    ref={ref}
                    direction="column"
                    positions={["center", "flex-start"]}
                    custom={2}
                    animate={inView ? "visible" : "hidden"}
                    variants={variants}
                    initial="hidden"
                    sx={{ marginBottom: [5, 0, 0] }}
                  >
                    <StaticImage
                      src="./home_partager.png"
                      alt="Image partager"
                      sx={{
                        width: "161px",
                      }}
                    />
                    <Heading
                      as="h3"
                      sx={{ color: "primary", marginTop: 3, marginBottom: 1 }}
                    >
                      Partager
                    </Heading>
                    <Text sx={{ textAlign: "center" }}>
                      {frontmatter.accueilMissions.partager}
                    </Text>
                  </MotionFlex>
                )}
              </InView>
            </Grid>
          </Container>
        </Box>
        <BackgroundVague
          width="100%"
          height={null}
          color="background3"
          sx={{
            bottom: "-1px",
            transform: "rotate(180deg)",
          }}
        />
        {events && (
          <>
            <Box
              as="section"
              sx={{
                backgroundColor: "background3",
                paddingTop: [6, 8, null],
              }}
            >
              <ContentTitle>Nos derniers événements</ContentTitle>
              <Box>
                {events.map(({ node: event }, index) => (
                  <Event
                    key={index}
                    alt={`Image ${event.frontmatter.title}`}
                    image={getImage(event.frontmatter.image)}
                    backgroundColor={index === 0 ? "white" : "secondary"}
                    direction={
                      index === 0
                        ? ["column", null, "row"]
                        : ["column", null, "row-reverse"]
                    }
                    to={event.fields.slug}
                  >
                    <EventDate>{event.frontmatter.date}</EventDate>
                    <EventTitle color={index === 1 ? "white" : "secondary"}>
                      {event.frontmatter.title}
                    </EventTitle>
                    <EventPlace
                      colorSvg={index === 1 ? "white" : "primary"}
                      colorText={index === 1 ? "white" : "secondary"}
                    >
                      {event.frontmatter.place}
                    </EventPlace>
                  </Event>
                ))}
              </Box>
            </Box>
            <Box
              backgroundColor="background3"
              sx={{
                paddingTop: [6, 10, null],
                paddingBottom: 6,
                textAlign: "center",
              }}
            >
              <Button
                sx={{
                  width: "285px",
                  textTransform: "uppercase",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                onClick={() => {
                  navigate("evenements");
                }}
              >
                Voir tous les événements
              </Button>
            </Box>
          </>
        )}
        <Contact />
      </Box>
    </Box>
  );
};

export default IndexPageTemplate;

const ContentTitle = (props) => (
  <Box sx={{ textAlign: "center", paddingBottom: [8, 14, null] }}>
    <Heading as="h2" variant="h1" {...props} />
    <Vague
      sx={{
        marginTop: 6,
      }}
    />
  </Box>
);

export const query = graphql`
  query IndexPageTemplate {
    allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        isFuture: { eq: false }
        frontmatter: {
          templateKey: { eq: "event" }
          visible: { eq: true }
          type: { eq: "Ponctuel" }
        }
        fields: { slug: { regex: "/(evenements)/" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            place
            templateKey
            date(formatString: "DD MMMM YYYY", locale: "fr")
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  aspectRatio: 1.666
                )
              }
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: "/" } }) {
      frontmatter {
        carousel {
          title
          image {
            desktop {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
            tablette {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
            mobile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
          btn {
            text
            link
          }
        }
        imagesHome {
          desktop {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
          tablette {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
        }
        btn
        highlights_home {
          highlights1 {
            number
            label
          }
          highlights2 {
            number
            label
          }
          highlights3 {
            number
            label
          }
        }
        accueilMissions {
          nettoyer
          partager
          sensibiliser
        }
      }
    }
  }
`;
