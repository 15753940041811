import React from "react";
import { jsx } from "theme-ui";
import { motion } from "framer-motion";
import { Box } from "theme-ui";

const Arrow = ({ onClick }) => {
  return (
    <motion.div
     whileHover={{ scale: 1.15 }}
    style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transformOrigin: "center",
        width: 70,
        height: 70,
        cursor: "pointer"
    }}
>
    <motion.svg
            width={70}
            height={70}
            viewBox="0 0 24 24"
            fill="none"
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
            onClick={onClick}
        >
            <path
                d="M15 18L9 12L15 6"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </motion.svg>
    </motion.div>
  );
};

export default Arrow;
