// components/CarouselTitle.js
import React from "react";
import { jsx, Box } from "theme-ui";
import { AnimatePresence } from "framer-motion";
import { MotionHeading } from "../../core";

export default function CarouselTitle({ text, index, isLeaving }) {

  const lines = typeof text === "string" ? text.split("\n") : [text];
  const variants = {
    initial: { y: "100%", opacity: 0 },
    animate: { y: "0%", opacity: 1 },
    exit: { y: "100%", opacity: 0 },
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        pointerEvents: "none",
        marginBottom: [0, 6, null]
      }}
    >
      <AnimatePresence exitBeforeEnter>
      {!isLeaving &&   (
        <Box key={index}>
        {lines.map((line, i) => (
          <Box
            key={i}
            sx={{
              overflow: "hidden", // masque ligne par ligne
              maxWidth: ["80vw", null, "820px"],
              textAlign: "center",
            }}
          >
            <MotionHeading
              as="h1" 
              color="white"
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{
                duration: 0.6,
                ease: "easeInOut",
              }}
            >
              {line}
            </MotionHeading>
          </Box>
        ))}
      </Box>
  )}
      </AnimatePresence>
    </Box>
  );
}
